import avatar from '../../assets/Avatar02.png'

function Presentation() {
  return (
    <section className="pres">
      <img src={avatar} alt="" />
    </section>
  )
}

export default Presentation
