import './_carousel.scss'
import { Carousel, CarouselCaption, CarouselItem } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import { Link } from 'react-router-dom'
function Slider({ slider }) {
  //   const settings = {
  //     dots: true,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  //   }
  return (
    <div className="slider">
      <Carousel className="slider__container">
        {slider.map((slider) => {
          return (
            <CarouselItem
              interval={5000}
              className="slider__item"
              key={slider.id}
            >
              <video autoPlay loop muted playsInline>
                <source src={slider.video} />
              </video>
              <CarouselCaption>
                <h3 className="slider__item--title">{slider.name}</h3>
                {slider.description ? <p>{slider.description}</p> : null}
                <Link to={slider.url} target="_blank">
                  {slider.url}
                </Link>
              </CarouselCaption>
            </CarouselItem>
          )
        })}
      </Carousel>
    </div>
  )
}

export default Slider
