import {
  faArrowDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import './_slider.scss'
import Shopify from '../../datas/shopify.json'

const CARDS = 10
const MAX_VISIBILITY = 3

const Card = ({ title, video, url, description }) => (
  <div className="card">
    <video autoPlay loop muted playsInline className="card-img">
      <source src={video} type="video/mp4" />
    </video>
    <div className="card-content">
      <h2 className="card-title">{title}</h2>
      {description && (
        <p className="card-description">
          {description} &nbsp;
          <FontAwesomeIcon icon={faArrowDown} />
        </p>
      )}
      {url != '' ? (
        <a className="card-link" href={url}>
          {url}
        </a>
      ) : (
        <p>Site plus en ligne</p>
      )}
    </div>
  </div>
)
function ActiveSlider({ children }) {
  const [active, setActive] = useState(2)
  const count = React.Children.count(children)
  return (
    <div className="carousel">
      {active > 0 && (
        <button className="nav" onClick={() => setActive((i) => i - 1)}>
          <FontAwesomeIcon className="left" icon={faChevronLeft} />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className="card-container"
          style={{
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 3,
            'pointer-events': active === i ? 'auto' : 'none',
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? 0 : 1,
            display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
          }}
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className="nav " onClick={() => setActive((i) => i + 1)}>
          <FontAwesomeIcon className="right" icon={faChevronRight} />
        </button>
      )}
    </div>
  )
}

const Slid = () => (
  <div className="container-slider">
    <ActiveSlider>
      {Shopify.map((shopify) => {
        return (
          <Card
            key={shopify.id}
            title={shopify.name}
            video={shopify.video}
            url={shopify.url}
            description={shopify.description}
          />
        )
      })}
    </ActiveSlider>
  </div>
)

export default Slid
