import { Link } from 'react-router-dom'
import './_header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

function Header() {
  return (
    <header className="header">
      <nav className="header__nav">
        <ul className="header__nav__links">
          <li className="header__nav__links--item">
            <Link
              target="_blank"
              to="https://www.linkedin.com/in/sheila-khedoo/"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </Link>
          </li>
          <li className="header__nav__links--item">
            <Link target="_blank" to="https://github.com/SheilaKhe">
              <FontAwesomeIcon icon={faGithub} />
            </Link>
          </li>
        </ul>
        <Link to="/">
          <p className="header__nav--copy">Sheila Khedoo </p>
        </Link>
      </nav>
    </header>
  )
}

export default Header
