import { useState } from 'react'
import Slider from '../../components/Carousel'
import { code } from '../../datas/code'
import { shopify } from '../../datas/shopify'
import './_work.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Swiper from 'swiper/bundle'
import { Navigation, Pagination } from 'swiper/modules'
import Slid from '../../components/Slider'
// function Work() {
//   const [open, setOpen] = useState(false)

//   let className = 'work__section--list--item'
//   if (!open) {
//     className += 'work__section--list--item--click'
//   }
//   return (
//     <main className="work">
//       <div className="work__section">
//         <ul className="work__section--list">
//           <li className={className} onClick={() => setOpen(!open)}>
//             Shopify
//           </li>
//           <li className={className} onClick={() => setOpen(!open)}>
//             Code
//           </li>
//         </ul>
//         <hr />
//       </div>
//       {/* <div className="work__slider">
//         <h2 className="work__slider--title">Shopify</h2>
//         <Slider slider={shopify} />
//       </div>
//       <div className="work__slider">
//         <h2 className="work__slider--title">Code</h2>
//         <Slider slider={code} />
//       </div> */}

//     </main>
//   )
// }
function Slide() {
  const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'vertical',
    loop: true,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  })
  return (
    // <div class="swiper">
    //   <div class="swiper-wrapper">
    //     <div class="swiper-slide">Slide 1</div>
    //     <div class="swiper-slide">Slide 2</div>
    //     <div class="swiper-slide">Slide 3</div>
    //     ...
    //   </div>
    //   <div class="swiper-pagination"></div>

    //   <div class="swiper-button-prev"></div>
    //   <div class="swiper-button-next"></div>

    //   <div class="swiper-scrollbar"></div>
    // </div>
    <Slid />
  )
}

export default Slide
