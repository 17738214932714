import { Link } from 'react-router-dom'
import './_contact.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

function Contact(params) {
  return (
    <main className="contact-cont">
      <div className="contact">
        <h1 className="contact__title">Bonjour</h1>
        <p>
          Need a beautiful, well-structured website that you can own and
          maintain yourself? Get in touch.
        </p>
        <div className="contact__links">
          <p>
            E-mail :
            <Link to="mailto:s.khedoo@outlook.fr"> s.khedoo@outlook.fr</Link>
          </p>
          <p>
            On the internet :
            <Link to="https://www.linkedin.com/in/sheila-khedoo/">
              {' '}
              LinkedIn{' '}
            </Link>
            <Link to=""> GitHub</Link>
          </p>
        </div>
      </div>
    </main>
  )
}

export default Contact
