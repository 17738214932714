import 'aos/dist/aos.css'
import AOS from 'aos'
import './_menu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <section className="menu" data-aos="fade-down" data-aos-duration="2000">
      <ul className="menu__links">
        <li className="menu__links--item">
          <Link to="/Work">Work</Link>
        </li>
        {/* <li className="menu__links--item">
          <Link to="">About</Link>
        </li> */}
        <li className="menu__links--item">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      {/* <Link to="">
        <FontAwesomeIcon icon={faArrowCircleDown} className="welcome__down" />
      </Link> */}
    </section>
  )
}

AOS.init()
export default Menu
