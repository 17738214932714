import Presentation from '../../components/Presentation'
import Menu from '../../components/Menu'
import Welcome from '../../components/Menu'
import './_home.scss'
import Avatar from '../../assets/Avatar02.png'

function Home() {
  return (
    <main className="home">
      <Menu />
      <img className="home__avatar" src={Avatar} alt="" />
      {/* <Presentation /> */}
    </main>
  )
}

export default Home
